/* eslint no-shadow: ["error", { "allow": ["state"] }] */

import {
  getIdentificationData,
  identify,
} from '@shared/store/utils/api';

import {
  IDENTIFICATION_RESULT__SET,
} from '@shared/store/mutation-types';

// set initial state (used to reset the store)
const setInitialState = () => ({
  errorCode: null,
  targetPath: null,
});

// state
const state = setInitialState();

const getters = {};

// const getters = {
//   errorCode: state => state.errorCode,
//   targetPath: state => state.targetPath,
// };

// actions
const actions = {
  /**
   *  Get new Tupas Bank data.
   *
   * * @param {Object} payload - Identification requirement data
   */
  getIdentificationData(context, payload) {
    return getIdentificationData(payload);
  },

  /**
   * Get API public keys
   */
  async identify({ commit, dispatch, state }, payload) {
    const response = await identify(payload);

    commit(IDENTIFICATION_RESULT__SET, response.data);

    if (state.errorCode) {
      dispatch('addException', 'IDENTIFICATION__INTERRUPTED', { root: true });
    }
  },
};

// mutations
const mutations = {
  [IDENTIFICATION_RESULT__SET](state, payload) {
    state.errorCode = payload.errorCode;
    state.targetPath = `/${payload.id.replace(/^\/+/, '')}`;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
