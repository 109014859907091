/* eslint no-param-reassign: ["error", { "props": false }] */
/* eslint no-console: ["error", { allow: ["warn", "error"] }] */
import Vue from 'vue';
import Vuex from 'vuex';
import cloneDeep from 'lodash.clonedeep';
import dataLayerPlugin from '@shared/store/plugins/dataLayer';
import actions from './actions';
import getters from './getters';
import state from './state';
import mutations from './mutations';

// Modules
import alerts from './modules/alerts';
import application from './modules/application';
import dataLayer from './modules/dataLayer';
import identification from './modules/identification';
import notifications from './modules/notifications';
import pricing from './modules/pricing';

Vue.use(Vuex);

export const storeData = {
  plugins: [dataLayerPlugin],
  actions,
  getters,
  mutations,
  state,
  modules: {
    alerts,
    application,
    dataLayer,
    identification,
    notifications,
    pricing,
  },
  strict: process.env.NODE_ENV !== 'production',
};

const store = new Vuex.Store(storeData);

export const initialState = JSON.parse(JSON.stringify(store.state));
export const getStoreData = () => cloneDeep(storeData);

export default store;
