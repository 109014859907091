import {
  extend,
  localize,
  setInteractionMode,
} from 'vee-validate';

import {
  between,
  digits,
  email,
  integer,
  required,
} from 'vee-validate/dist/rules';

import dictionary from '@shared/validators/dictionary';
import customRules from '@shared/validators/rules';

const {
  fields,
  names,
  messages,
} = dictionary;

// merge default and custom rules
const mergedRules = {
  between,
  digits,
  email,
  integer,
  required,
  ...customRules,
};

localize({
  en: {
    fields,
    names,
    messages,
  },
});

/**
 * Interaction Modes:
 * -  aggressive: Triggered when the user presses a key (on input).
 * -  passive: Triggered when the form is submitted.
 * -  lazy: Triggered when the user leaves the input (on blur or change).
 * -  eager: Is a combination of aggressive and lazy, as it first validates when the user leaves
 *      the input (on blur or change) then if the input is invalid it will behave aggressively
 *      until the input is valid again and it will go back to being lazy.
 */
setInteractionMode('eager');

// For every imported rule we defined above.
Object.keys(mergedRules).forEach((rule) => {
  // Add the rule.
  extend(rule, mergedRules[rule]);
});
