const getters = {
  /**
   * Get state.mobile.
   */
  getMobile: state => state.mobile,

  /**
   * Get page loader status
   */
  showPageLoader: state => state.showPageLoader,
};

export default getters;
