/* eslint-disable no-param-reassign */
import { DataLayer } from '@shared/services/dataLayer';

export default (store) => {
  try {
    store.$dataLayer = DataLayer;
  } catch (error) {
    console.error(error);
  }
};
