import Vue from 'vue';
import VueBrowserUpdate from 'vue-browserupdate';
import { translate } from 'vue-gettext';

const { gettext: $gettext } = translate;

Vue.use(VueBrowserUpdate, {
  options: {
    // Your browser update options
    container: document.body,
    required: {
      i: 11,
      f: -6,
      c: -6,
      e: -2,
      o: -2,
      s: -2,
    },
    insecure: true,
    api: 2018.06,
    reminder: 0,
    text: $gettext('The browser you are using ({brow_name}) is outdated. We can not guarantee the loan application to function as intended. Please update your browser by clicking this notification.'),
  },
});
