/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
import BaseDataLayerService from '@shared/services/dataLayer/BaseDataLayerService';

class PageDataLayerService extends BaseDataLayerService {
  /**
   * Datalayer push for real page load
   *
   * @param {String} country - country code
   * @param {String} currencyCode - 3 character currency code
   * @param {String} customerStatus - customer status
   * @param {String} hash - hash ID string
   * @param {String} fiSite - saldo | vippi | limiitti | laina
   * @param {String} userId - user ID string
   */
  onAppLoaded({
    appliedAmount = this.required('appliedAmount'),
    appliedLoan = this.required('appliedLoan'),
    country = this.required('country'),
    currencyCode = this.required('currencyCode'),
    // TODO: DataLayer where we get actual userId and customer status?
    customerStatus = 'new',
    fiSite,
    hash,
    languageCode,
    legacyFunnel,
    selectedAmount,
    selectedLoan,
    userId,
  } = {}) {
    this.country = country;
    this.currencyCode = currencyCode;
    this.userId = userId;

    this.push({
      event: 'appLoaded',
      appliedAmount,
      appliedLoan,
      currencyCode: this.currencyCode,
      customerStatus,
      fiSite,
      hash,
      languageCode,
      legacyFunnel,
      selectedAmount,
      selectedLoan,
      userId: this.userId,
    });
  }

  /**
   * Datalayer push for 'continue' button clicks
   */
  onValidationClick() {
    this.push({
      event: 'stepValidationClick',
    });
  }

  /**
   * Datalayer push if the form validation fails in the step
   */
  onValidationFail() {
    this.push({
      event: 'stepValidationFail',
    });
  }

  /**
   * Handle UTM parameters for continue application datalayer push
   *
   * @param {String} campaign - campaing of the original session
   * @param {String} medium - medium of the original session
   * @param {String} source - source of the original session
   * @param {String} referrer - source of the original referrer
   */
  handleUtmParameters({
    campaign,
    medium,
    source,
    referrer,
  } = {}) {
    const _referrer = referrer ? new URL(referrer) : {};

    if (campaign) {
      this.campaign = campaign;
      this.medium = medium;
      this.source = source;
    } else if (_referrer.hostname) {
      const searchEngines = ['google', 'bing', 'yahoo', 'msn', 'yandex', 'baidu', 'duckduckgo', 'ecosia'];

      for (const engine of searchEngines) {
        if (referrer.includes(engine)) {
          this.medium = 'organic';
          this.source = engine;
        }
      }

      if (!this.medium && referrer) {
        this.medium = 'referrer';
        this.source = referrer;
      }
    }
  }

  /**
   * Datalayer push for situations where the user returns to continue a pending application
   * This code ensures that we can capture the referral information of the first campaign
   *
   * @param {Object} params - original utm parameters
   */
  restoreOriginalUtmParameters(params = {}) {
    this.handleUtmParameters(params);

    this.push({
      event: 'continueApplication',
      originalCampaign: this.campaign,
      originalMedium: this.medium,
      originalSource: this.source,
    });
  }

  /**
   * Update loan application progress
   *
   * @param {String} status - application status
   */
  setApplicationStatus({
    status: applicationProgress,
  }) {
    this.push({
      event: 'applicationProgress',
      applicationProgress,
    });
  }

  /**
   * Datalayer push the results of authentication check
   *
   * @param {String} result - authentication result (fail | pass)
   */
  setAuthenticationResult({
    result: authenticationResults,
  }) {
    this.push({
      event: 'authenticationResults',
      authenticationResults,
    });
  }

  /**
   * Datalayer push for situations where currency is changed
   *
   * @param {String} currencyCode - currency code
   */
  setCurrencyCode({
    currencyCode = this.required('currencyCode'),
  }) {
    this.push({
      event: 'currencyCode',
      currencyCode,
    });
  }

  /**
   * Datalayer push for situations where user moves to the new 'page' of the application
   *
   * @param {String} page - current page / step name
   * @param {String} title - the value of the heading
   */
  setCurrentPage({
    page: currentPage = this.required('currentPage'),
    title: currentPageTitle = this.required('currentPageTitle'),
  }) {
    if (this._country && this.product.name) {
      const step = this.steps.findIndex(s => s === currentPage);

      this.currentPage = currentPage;
      this.step = step >= 0 ? step + 1 : undefined;
    }

    this.push({
      event: 'currentPage',
      currentPage,
      currentPageTitle,
    });
  }

  /**
   * Datalayer push for situations where customer status is changed
   *
   * @param {String} customerStatus - customer status
   */
  setCustomerStatus({
    customerStatus = this.required('customerStatus'),
  }) {
    this.push({
      event: 'customerStatus',
      customerStatus,
    });
  }

  solvencyCheckFinished() {
    this.push({
      event: 'solvencyCheck',
      solvencyCheckStatus: 'finished',
    });
  }

  solvencyCheckStarted() {
    this.push({
      event: 'solvencyCheck',
      solvencyCheckStatus: 'started',
    });
  }
}

export default PageDataLayerService;
