/* eslint-disable no-shadow */
import Vue from 'vue';

/**
 * Format a number in 'cents' to a localized currency
 * ie: '199000' | currency => '19,90 €'
 *
 * @param   {String}  locale - Unicode locale identifier
 * @param   {String}  currency - Currency to use in formatting
 * @param   {String}  [currencyDisplay = 'symbol'] - How to display the currency in formatting
 * @param   {Number}  [digits = 0] - Number of digits
 * @param   {String} [style = 'currency'] - The formatting style to use
 *
 * @returns {String}                      - Formatted string
 */
export const currency = (value, {
  locale = 'fi-FI',
  currencyDisplay = 'symbol', // symbol | code | name
  digits = 2,
  style = 'currency', // currency | decimal
} = {}) => {
  if (!value && value !== 0) return '';

  return new Intl.NumberFormat(locale, {
    maximumFractionDigits: digits,
    minimumFractionDigits: digits,
    style,
    currency: process.env.VUE_APP_CURRENCY,
    currencyDisplay,
  }).format(value / 100);
};

export const parseDate = (isoString) => {
  const [date, time] = isoString.split('T');
  const [year, month, day] = date.split('-');
  const [hours, minutes, seconds] = time.split(':');

  return new Date(Date.UTC(
    year,
    month - 1,
    day,
    hours,
    minutes,
    parseInt(seconds, 10),
  ));
};

export const date = (value, { type = 'date', locale = 'fi-FI' } = {}) => {
  if (!value) return '';

  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  const types = {
    date: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      timeZone,
    },
    month: {
      year: 'numeric',
      month: 'long',
      timeZone,
    },
    time: {
      hour: 'numeric',
      minute: 'numeric',
      timeZone,
    },
  };

  types.full = { ...types.date, ...types.time };

  const date = parseDate(new Date(value).toISOString());
  return new Intl.DateTimeFormat(locale, types[type]).format(date);
};

/**
 * Format a number to a percentage
 * ie: '19' | percent => '19,00 %'
 *
 * @param   {Number} value         - Value
 * @param   {Number} [digits = 2]  - Number of digits
 *
 * @returns {String}               - Formatted string
 */
export const percent = (value, {
  digits = 2,
} = {}) => {
  if (typeof value === 'undefined') return '';
  return String(`${parseFloat(value).toFixed(digits)} %`).replace('.', ',');
};

/**
 * Sort objects in an array by value
 *
 * @param {Array} items - Array of objects to be sorted
 * @param {String} key  - key to sort by
 */
export const sortBy = (items, {
  key,
  reversed = false,
} = {}) => {
  if (!items) return [];

  return items.sort((a, b) => {
    const A = typeof a === 'string' ? a[key].toLowerCase() : a[key];
    const B = typeof b === 'string' ? b[key].toLowerCase() : b[key];

    if (!reversed) {
      if (A > B) return 1;
      if (A < B) return -1;
    } else {
      if (A > B) return -1;
      if (A < B) return 1;
    }

    return 0;
  });
};

const filters = {
  currency,
  date,
  percent,
  sortBy,
};

// Build filters from the filters object
Object.keys(filters).forEach(key => Vue.filter(key, filters[key]));

// Global filters
Vue.prototype.$filters = Vue.options.filters;

export default filters;
