import { notification } from '@shared/config';
import { translate } from 'vue-gettext';

const { gettext: $gettext } = translate;

export default {
  messages: {
    alpha_dash: $gettext('The value may contain alpha-numeric characters as well as dashes and underscores'),
    alpha_num: $gettext('The value may only contain alpha-numeric characters'),
    alpha_spaces: $gettext('The value may only contain alphabetic characters as well as spaces'),
    alpha: $gettext('The value may only contain alphabetic characters'),
    bankAccount: notification.BANK_ACCOUNT__INVALID,
    businessId: notification.BUSINESS_ID__INVALID,
    between: $gettext('The value must be between {min} and {max}'),
    confirmed: $gettext('The confirmation does not match'),
    decimal: $gettext('The value should not contain more than {decimals} decimals'),
    digits: $gettext('The value must be numeric and contain exactly {length} digits'),
    email: notification.EMAIL__INVALID,
    error: notification.VALUE__INVALID,
    excluded: $gettext('The value is not valid'),
    ext: $gettext('The file is not valid'),
    iban: notification.IBAN__INVALID,
    integer: $gettext('The value must be an integer'),
    length: $gettext('The value must be {length} long'),
    licenseNumber: notification.LICENSE_NUMBER__INVALID,
    max_value: $gettext('The value must be {max} or less'),
    max: $gettext('The value may not be greater than {length} characters'),
    min_value: $gettext('The value must be {min} or more'),
    min: $gettext('The value must be at least {min} characters'),
    notNegativeNumber: notification.VALUE__INVALID,
    numeric: $gettext('The value may only contain numeric characters'),
    oneOf: $gettext('The value is not valid'),
    phone: notification.PHONE_NUMBER__INVALID,
    regex: $gettext('The format is invalid'),
    required_if: $gettext('The field is required'),
    required: notification.REQUIRED_FIELD,
    requiredCheckbox: notification.REQUIRED_CHECKBOX,
    requireOne: notification.REQUIRE_ONE,
    ssn: notification.SSN__INVALID,
    seSsn: notification.SSN__INVALID,
    unique: notification.VALUE__NOT_UNIQUE,
  },
  fields: {
    'owner-name': {
      error: notification.REQUIRED_FIELD,
    },
    'owner-percentage': {
      error: $gettext('Invalid shareholding percentage'),
      max_value: $gettext('Shareholding should be {max} % or less'),
    },
    'owner-ssn': {
      error: notification.SSN__INVALID,
      unique: $gettext('Social security number has already been entered'),
    },
    'signee-email': {
      error: notification.EMAIL__INVALID,
    },
    'signee-phone': {
      error: notification.PHONE_NUMBER__INVALID,
    },
    carInstalmentAmount: {
      between: notification.VALUE__BETWEEN,
      decimal: notification.VALUE__INTEGER,
    },
    carLoanLimit: {
      between: notification.VALUE__BETWEEN,
      decimal: notification.VALUE__INTEGER,
    },
    confirmCode: {
      digits: notification.VALUE__LENGTH.replace('{max}', 6),
      error: notification.CONFIRM_CODE__INVALID,
    },
    email: {
      error: notification.EMAIL__INVALID,
    },
    iban: {
      error: notification.IBAN__INVALID,
    },
    phone: {
      error: notification.PHONE_NUMBER__INVALID,
    },
    ringData: {
      error: $gettext('Customer Data Review must be accepted'),
    },
    ssn: {
      error: notification.SSN__INVALID,
    },
    seSsn: {
      error: notification.SSN__INVALID,
    },
    withdrawalAmount: {
      between: notification.VALUE__BETWEEN,
    },
  },
  names: {
    accommodation: $gettext('Form of housing'),
    accommodationType: $gettext('Housing Type'),
    assetsTotal: $gettext('Property'),
    carInstalmentAmount: $gettext('Monthly Instalment'),
    carLoanLimit: $gettext('Sum'),
    confirmCode: $gettext('Verification code'),
    education: $gettext('Education'),
    email: $gettext('Email'),
    employerName: $gettext('Employer'),
    employmentStartDate: $gettext('Employment start date'),
    householdIncomeCombined: $gettext('Household income'),
    incomeTypes: $gettext('Personal sources of income'),
    instalmentPerMonth: $gettext('Instalment'),
    loansTotal: $gettext('Loans'),
    openLoanTypes: $gettext('Open loans'),
    otherExpenses: $gettext('Expenses'),
    personalIncome: $gettext('Personal income'),
    phone: $gettext('Phone number'),
    ringData: $gettext('Customer Data Review'),
    sizeOfFamily: $gettext('Household size'),
    ssn: $gettext('Identity number'),
    workRelationshipType: $gettext('Type of employment'),
    workSituation: $gettext('Employment situation'),
  },
};
