/* eslint no-console: ["error", { allow: ["warn", "error"] }] */
import Vue from 'vue';
import VueWait from 'vue-wait';
import Router from 'vue-router';
import EventLogger from '@saldo/event-logger';
import { sync } from 'vuex-router-sync';
import { detect } from 'detect-browser';
import 'babel-polyfill';
import '@shared/plugins/gettextFi';
import '@shared/filters';
import '@shared/plugins/browserUpdate';
import '@shared/plugins/dataLayerFi';
import '@shared/plugins/vee-validate';
import '@shared/assets/styles/style.scss';
import App from '@shared/App';
import vuetify from '@shared/plugins/vuetify';
import router from '@shared/router';
import store from '@shared/store';

const browser = detect();

Vue.config.productionTip = false;

Vue.prototype.$eventLogger = new EventLogger();

Vue.use(Router);
Vue.use(VueWait);

// Sync vue-router's current $route as part of vuex store's state.
sync(store, router);

// global event bus
Vue.prototype.$bus = new Vue();

const showNumbers = browser.os !== 'Android OS'
  || (
    browser.os === 'Android OS'
    && browser.name !== 'chrome'
    && browser.name !== 'android'
  )
  || (
    browser.os === 'Android OS'
    && parseInt(browser.version, 10) > 58
    && browser.name === 'chrome'
  );

Vue.prototype.$showNumbers = showNumbers;

// global components
Vue.component('CwFormLoader', () => import('@shared/components/FormLoader'));
Vue.component('CwHero', () => import('@shared/components/Hero'));
Vue.component('CwPage', () => import('@shared/components/Page'));

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  vuetify,
  wait: new VueWait({
    useVuex: true,
    registerComponent: false,
    registerDirective: false,
  }),
  components: { App },
  template: '<App/>',
});
