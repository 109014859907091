import Vue from 'vue';
import GetTextPlugin from 'vue-gettext';
import translations from '@fi/translations.json';

Vue.use(GetTextPlugin, {
  availableLanguages: { en: 'English', fi: 'Finnish', sv: 'Swedish' },
  defaultLanguage: process.env.VUE_APP_LANGUAGE,
  silent: true,
  translations,
});
