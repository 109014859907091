/* eslint-disable no-restricted-syntax */
/* eslint-disable no-param-reassign */
// import { products } from 'products';
import { DATALAYER_APPLOADED__SET, DATALAYER_STEP_DATA__SET } from '@shared/store/mutation-types';
import { getSteps } from '@shared/products';

const moduleActions = {
  async handleDataLayerEvents({ state, getters }, { number, page, refresh }) {
    const { dataLayerEvents } = state.stepData.get(page);

    if (!dataLayerEvents || !Object.keys(dataLayerEvents).length) return;

    for (const [event, options] of Object.entries(dataLayerEvents)) {
      // eslint-disable-next-line no-continue
      if (refresh && options.ignoreOnPageRefresh) continue;

      const params = Object.entries(options.getters || {}).reduce((obj, [key, getter]) => {
        obj[key] = getters[getter];

        return obj;
      }, options.parameters || {});

      if (!options.ignoreStepNumber) {
        params.step = +number;
      }

      this.$dataLayer[event](params);
    }
  },

  async handlePageChange({ dispatch }, { refresh = false } = {}) {
    dispatch('onAppLoaded');
    await dispatch('setProduct');
    dispatch('onPageChange', { refresh });
  },

  onAppLoaded({
    commit,
    getters,
    rootGetters,
    rootState,
    state,
  }) {
    if (state.appLoaded) return;

    const { hashId } = rootState.application.initialData;
    const { appliedProduct, selectedOffer } = getters;

    if (!hashId) return;

    const availableSites = ['saldo', 'vippi', 'limiitti', 'laina'];
    const [site] = appliedProduct.saleChannel.split('.');

    this.$dataLayer.onAppLoaded({
      // customerStatus,
      appliedAmount: appliedProduct.loanLimit,
      appliedLoan: appliedProduct.productName,
      country: appliedProduct.country,
      currencyCode: appliedProduct.currency,
      fiSite: availableSites.includes(site) ? site : 'saldo',
      hash: hashId,
      languageCode: rootGetters['application/locale'].toLowerCase(),
      legacyFunnel: true,
      selectedAmount: selectedOffer && selectedOffer.loanLimit,
      selectedLoan: selectedOffer && selectedOffer.productName,
      userId: hashId,
    });

    commit(DATALAYER_APPLOADED__SET);
  },

  onImpressionClick({ dispatch }) {
    dispatch('setProduct');
    this.$dataLayer.impressionClick();
  },

  onImpressionView({ rootState }) {
    const impressions = rootState.application.offers
      .map((offer, index) => ({
        brand: offer.brandName ? offer.brandName.split('.')[0] : undefined,
        category: offer.type,
        list: 'Loan offers',
        name: offer.loanProgramName,
        position: index + 1,
        price: (offer.loanLimit / 100).toFixed(2),
        quantity: 1,
        variant: (offer.instalmentAmount / 100).toFixed(2),
      }));

    this.$dataLayer.impressionView(impressions);
  },

  async onPageChange({ dispatch }, { refresh }) { // eslint-disable-line
    dispatch('setCurrentPage');

    const stepNumbers = this.$dataLayer.getStepRange();

    for await (const step of stepNumbers) {
      const [[number, page]] = Object.entries(step);

      await dispatch('handleDataLayerEvents', { number, page, refresh });
    }
  },

  restoreOriginalUtmParameters({ rootState }) {
    const {
      originalReferrer: referrer,
      originalUtmCampaign: campaign,
      originalUtmMedium: medium,
      originalUtmSource: source,
    } = rootState.application.initialData;

    this.$dataLayer.restoreOriginalUtmParameters({
      source,
      medium,
      campaign,
      referrer,
    });
  },

  setCurrentPage({ getters }) {
    this.$dataLayer.setCurrentPage({
      page: getters.page || 'not available',
      title: getters.title || 'not available',
    });
  },

  async setProduct({ dispatch, getters }) {
    const { appliedProduct, selectedOffer } = getters;

    if (!appliedProduct.productName) return;

    this.$dataLayer.product = {
      appliedProduct: appliedProduct && appliedProduct.productName,
      brand: (selectedOffer && selectedOffer.brand)
        || (appliedProduct && appliedProduct.brand),
      instalmentAmount: (selectedOffer && selectedOffer.instalmentAmount)
        || (appliedProduct && appliedProduct.instalmentAmount),
      loanLimit: (selectedOffer && selectedOffer.loanLimit)
        || (appliedProduct && appliedProduct.loanLimit),
      offerProductName: selectedOffer && selectedOffer.productName,
      productName: (selectedOffer && selectedOffer.productName)
        || (appliedProduct && appliedProduct.productName),
      type: (selectedOffer && selectedOffer.type)
        || (appliedProduct && appliedProduct.type),
    };

    this.$dataLayer.country = appliedProduct.country;

    await dispatch('setSteps', {
      processRole: getters.processRole,
      product: appliedProduct.productName,
    });
  },

  async setSteps({ commit, rootGetters }, { product, processRole }) {
    const { steps, options } = await getSteps({
      country: rootGetters['application/country'],
      product,
      processRole,
    });

    this.$dataLayer.steps = steps;

    commit(DATALAYER_STEP_DATA__SET, options);
  },
};

const moduleGetters = {
  applicationId: (state, getters, rootState) => rootState.application.initialData.id,

  appliedProduct: (state, getters, rootState) => {
    const {
      appliedInstalmentAmount,
      appliedLoanLimit,
      withdrawalAmount,
    } = rootState.application.initialData;

    const {
      brand,
      country,
      currency,
      gaType,
      productName,
      saleChannel,
    } = rootState.application.product;

    const instalmentAmount = +appliedInstalmentAmount || +withdrawalAmount;
    const loanLimit = +appliedLoanLimit || +withdrawalAmount;

    return {
      brand,
      country,
      currency,
      instalmentAmount: instalmentAmount ? (instalmentAmount / 100).toFixed(2) : undefined,
      loanLimit: loanLimit ? (loanLimit / 100).toFixed(2) : undefined,
      productName,
      type: gaType,
      saleChannel,
    };
  },

  offerStatus: (state, getters, rootState) => (rootState.application.initialData
    .isDownsell === '1' ? 'downsell' : 'approved'),

  revenue: (state, getters, rootState) => {
    const { withdrawalAmount } = rootState.application.initialData;

    return withdrawalAmount ? (withdrawalAmount / 100).toFixed(2) : getters.selectedOffer.loanLimit;
  },

  selectedOffer: (state, getters, rootState) => {
    const id = getters.selectedOfferId;
    if (!(id && id > 0)) return {};
    const selectedOffer = rootState.application.offers
      .find(offer => +offer.id === +id);

    if (!selectedOffer) return {};

    const instalmentAmount = +selectedOffer.instalmentAmount;
    const loanLimit = +selectedOffer.loanLimit;

    return {
      brand: selectedOffer.brandName ? selectedOffer.brandName.split('.')[0] : undefined,
      instalmentAmount: instalmentAmount ? (instalmentAmount / 100).toFixed(2) : undefined,
      loanLimit: loanLimit ? (loanLimit / 100).toFixed(2) : undefined,
      productName: selectedOffer.loanProgramName,
      type: selectedOffer.type,
    };
  },

  selectedOfferId: (state, getters, rootState) => {
    const { selectedOfferId } = rootState.application.initialData;
    const { selectedOffer } = rootState.application;

    return selectedOfferId || selectedOffer.id;
  },

  // Current page (schema id)
  page: (state, getters, rootState) => rootState.application
    && rootState.application.initialData
    && rootState.application.initialData.currentPage,

  processRole: (state, getters, rootState) => (rootState.application
    && rootState.application.initialData
    && rootState.application.initialData.processRole) || 'initial',

  // Current page title
  title: (state, getters) => (state.stepData
    && state.stepData.get(getters.page) && state.stepData.get(getters.page).title)
    || undefined,
};

const moduleMutations = {
  /**
   * Set appLoad to state
   */
  [DATALAYER_APPLOADED__SET](state) {
    state.appLoaded = true;
  },

  [DATALAYER_STEP_DATA__SET](state, data) {
    state.stepData = data;
  },
};

const state = () => ({
  appLoaded: false,
  stepData: new Map(),
});

export default {
  namespaced: true,
  actions: moduleActions,
  getters: moduleGetters,
  mutations: moduleMutations,
  state,
};
