/* eslint-disable import/prefer-default-export */
import { translate } from 'vue-gettext';

const {
  gettext: $gettext,
  gettextInterpolate: $gettextInterpolate,
} = translate;

const genericSteps = {
  99: {
    name: 'reject',
    title: $gettext('The application was suspended'),
    dataLayerEvents: {
      checkout: {
        parameters: {
          option: 'rejected',
        },
      },
      setApplicationStatus: {
        parameters: {
          status: 'reject',
        },
      },
    },
  },
  100: {
    name: 'applicationNotFound',
    title: $gettext('The Application was not found'),
  },
  101: {
    name: 'pageNotFound',
    title: $gettextInterpolate($gettext('%{ openingTag }OOPS!%{ closingTag } Page not found.'), {
      openingTag: '<span>',
      closingTag: '</span>',
    }),
  },
  102: {
    name: 'connectionError',
    title: $gettext('Connection error'),
  },
  103: {
    name: 'linkExpired',
    title: $gettext('The application link has expired'),
  },
};

const getStepConfig = async (country, product) => {
  const productName = product.replace(/\./g, '');
  const imports = {
    fi: [
      import('@fi/products'),
      import('@fi/products/genericSteps'),
    ],
    se: [
      import('@se/products'),
      import('@se/products/genericSteps'),
    ],
  };

  const [products, generic] = await Promise.all(imports[country]);

  return {
    options: products.default.get(productName),
    generic: generic.default,
  };
};

const getAdditionalSteps = ({
  generic,
  options,
}) => (options.additionalSteps || [])
  .reduce((items, item) => ({ ...items, ...generic[item] }), genericSteps);

const createGenericStepsMap = ({ additionalSteps }) => Object.entries(additionalSteps)
  .reduce((items, [, { name, title, dataLayerEvents }]) => {
    items.set(name, { title, dataLayerEvents });

    return items;
  }, new Map());

const createSteps = ({
  additionalSteps,
  options,
  processRole,
}) => {
  const steps = [...options[processRole].keys()];

  Object.entries(additionalSteps).forEach(([index, { name }]) => {
    steps[index] = name;
  });

  return steps;
};

export const getSteps = async ({ country, product, processRole }) => {
  try {
    const { options, generic } = await getStepConfig(country, product);
    const additionalSteps = getAdditionalSteps({ options, generic });
    const steps = createSteps({
      additionalSteps,
      generic,
      options,
      processRole,
    });

    return {
      steps,
      options: new Map([
        ...options[processRole],
        ...createGenericStepsMap({ additionalSteps }),
      ]),
    };
  } catch (error) {
    console.error(error);

    return { steps: [], title: '', dataLayerEvents: {} };
  }
};
