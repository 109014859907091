import Vue from 'vue';
import Vuetify, { VForm } from 'vuetify/lib';
import colors from 'vuetify/es5/util/colors';

Vue.use(Vuetify, {
  components: {
    VForm, // added globally to be used as a tag in the validation observers
  },
});

const saldo = {
  name: 'saldo',
  accent: colors.red.accent2,
  error: '#f74936',
  info: colors.blue.base,
  primary: '#7a37d2',
  secondary: '#370b45',
  success: colors.lightGreen.base,
  warning: '#9c1F11',
};

const vippi = {
  name: 'vippi',
  accent: colors.red.accent2,
  error: colors.red.base,
  info: colors.blue.base,
  primary: colors.lightBlue.base,
  secondary: colors.amber.darken1,
  success: colors.lightGreen.base,
  warning: colors.amber.darken3,
};

const vuetify = new Vuetify({
  icons: {
    iconfont: 'md',
  },
  theme: {
    defaultTheme: saldo,
    themes: {
      saldo,
      vippi,
    },
  },
});

export default vuetify;
