import notification from './notification';

export default {
  101: {
    message: notification.PRODUCT_KEY__INVALID,
    type: 'global',
  },
  102: {
    message: notification.ERROR__UNKNOWN,
    type: 'global',
  },
  103: {
    message: notification.ERROR__UNKNOWN,
    type: 'global',
  },
  104: {
    message: notification.ERROR__UNKNOWN,
    type: 'global',
  },
  105: {
    message: notification.ERROR__VALIDATION,
    type: 'global',
  },
  110: {
    message: notification.TERMS__SAVE_FAILED,
    type: 'global',
  },
  114: {
    message: notification.CONFIRM_CODE__INVALID,
    type: 'confirmCode',
  },
  117: {
    message: notification.VERIFICATION_CODE__MAXIMUM_ATTEMPTS_REACHED,
    type: 'maximumAttempts',
  },
  119: {
    message: notification.IDENTIFICATION__CANCELLED,
    type: 'identificationCancelled',
  },
  120: {
    message: notification.IDENTIFICATION__ERROR,
    type: 'identificationError',
  },
  121: {
    message: notification.IDENTIFICATION__ERROR,
    type: 'identificationError',
  },
};
