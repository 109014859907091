import * as types from './mutation-types';

const actions = {
  /**
   * Add an exception
   *
   * @param {Object} payload - Exception data
   */
  addException({ commit }, payload) {
    commit(types.EXCEPTION__CLEAR);
    commit(types.EXCEPTION__ADD, payload);
  },

  /**
   * Clear all exceptions
   */
  clearExceptions({ commit }) {
    commit(types.EXCEPTION__CLEAR);
  },

  /**
   * Remove an exception
   *
   * @param {Object} payload - Exception data
   */
  removeException({ commit }, payload) {
    commit(types.EXCEPTION__REMOVE, payload);
  },

  /**
   * Reset state to initial values.
   */
  resetState({ commit }) {
    commit(types.STATE__RESET);
  },

  /**
   * Toggle state.mobile based on the browser width.
   */
  toggleMobile({ commit }, payload) {
    commit(types.MOBILE__SET, payload);
  },

  /**
   *
   * @param {Boolean} payload - a boolean for showing or hiding the page loader
   */
  togglePageLoader({ commit }, payload = false) {
    commit(types.SHOW_PAGE_LOADER, payload);
  },
};

export default actions;
