import { translate } from 'vue-gettext';

const { gettext: $gettext } = translate;

export default {
  vippi: {
    brandName: 'Vippi.fi',
    continuousLoan: {
      name: 'v-continuous',
      type: $gettext('Flexible Credit'),
    },
    fixedPeriodContinuousLoan: {
      name: 'v-continuous',
      type: $gettext('500 € Flexible credit'),
    },
    instalmentLoan: {
      name: 'v-instalment',
      type: $gettext('Consumer credit'),
    },
    colors: {
      primary: '#2b3aa5',
      secondary: '#ec6608',
      tertiary: '#282828',
    },
  },
  saldo: {
    brandName: 'Saldo.com',
    continuousLoan: {
      name: 's-continuous',
      type: $gettext('Flexible Credit'),
    },
    brokeredContinuousLoan: {
      name: 's-continuous',
      type: $gettext('Flexible Credit'),
    },
    fixedPeriodContinuousLoan: {
      name: 's-continuous',
      type: $gettext('500 € Flexible credit'),
    },
    instalmentLoan: {
      name: 's-instalment',
      type: $gettext('Consumer credit'),
    },
    carInstalmentLoan: {
      name: 's-car-instalment',
      type: $gettext('Car loan'),
    },
    businessInstalmentLoan: {
      name: 's-business-instalment',
      type: $gettext('Corporate loan'),
    },
    brokeredInstalmentLoan: {
      name: 's-instalment',
      type: $gettext('Consumer credit'),
    },
    colors: {
      primary: '#7a37d2',
      secondary: '#370b45',
      tertiary: '#282828',
    },
  },
};
