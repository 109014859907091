/* eslint-disable no-underscore-dangle */
/* eslint no-param-reassign: ["error", { "props": false }] */
/* eslint no-shadow: ["error", { "allow": ["state"] }] */

import {
  NOTIFICATION__ADD,
  NOTIFICATION__REMOVE,
  STATE__RESET,
} from '@shared/store/mutation-types';

// set initial state (used to reset the store)
const setInitialState = () => ({
  notifications: [],
});

// state
const state = setInitialState();

// getters
const getters = {
  getNotifications: state => state.notifications,
};

// actions
const actions = {
  /**
   * Add a notification to notification queue.
   */
  addNotification({ commit }, notification) {
    commit(NOTIFICATION__ADD, notification);
  },

  /**
   * Remove a notification from the notification queue.
   */
  removeNotification({ commit }, notification) {
    commit(NOTIFICATION__REMOVE, notification);
  },

  /**
   * Reset state to initial state.
   */
  resetState({ commit }) {
    commit(STATE__RESET);
  },
};

// mutations
const mutations = {
  /**
   * Add a notification to notification queue.
   */
  [NOTIFICATION__ADD](state, notification) {
    state.notifications.push(notification);
  },

  /**
   * Remove a notification from the notification queue.
   */
  [NOTIFICATION__REMOVE](state, notification) {
    const index = state.notifications.indexOf(notification);
    state.notifications = [
      ...state.notifications.slice(0, index),
      ...state.notifications.slice(index + 1),
    ];
  },

  [STATE__RESET](state) {
    const initial = setInitialState();
    Object.keys(initial)
      .forEach((key) => {
        this._vm.$set(state, key, initial[key]);
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
