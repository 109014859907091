/* eslint-disable no-underscore-dangle */
/* eslint no-param-reassign: ["error", { "props": false }] */
/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import {
  ALERT__SET,
  DIALOG__ADD,
  DIALOG__REMOVE,
  STATE__RESET,
} from '@shared/store/mutation-types';

// set initial state (used to reset the store)
const setInitialState = () => ({
  alerts: {},
  dialogs: [],
});

// state
const state = setInitialState();

// getters
const getters = {
  getAlerts: state => state.alerts,
  getDialogs: state => state.dialogs,
};

// actions
const actions = {
  /**
   * Add an alert to the alert object.
   *
   * @param {String} type - type of the alert
   */
  addDialog({ commit }, payload) {
    commit(DIALOG__ADD, payload);
  },

  /**
   * Add an alert to the alert object.
   *
   * @param {String} type - type of the alert
   */
  removeDialog({ commit }, payload) {
    commit(DIALOG__REMOVE, payload);
  },

  /**
   * Reset state to initial state.
   */
  resetState({ commit }) {
    commit(STATE__RESET);
  },

  /**
  * Add an alert to the alert object.
  *
  * @param {Object} payload
  * @param {String} payload.name - name of the alert
  * @param {String} payload.state - state of the alert
  */
  setAlert({ commit }, payload) {
    commit(ALERT__SET, payload);
  },
};

// mutations
const mutations = {
  /**
   * Add an alert to the alert object.
   */
  [ALERT__SET](state, alert) {
    state.alerts[alert.name] = alert.status;
  },

  [DIALOG__ADD](state, data) {
    if (state.dialogs.indexOf(data) === -1) {
      state.dialogs.push(data);
    }
  },

  [DIALOG__REMOVE](state, data) {
    if (state.dialogs[0] === data) {
      state.dialogs.splice(0, 1);
    }
  },

  [STATE__RESET](state) {
    const initial = setInitialState();
    Object.keys(initial)
      .forEach((key) => {
        this._vm.$set(state, key, initial[key]);
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
