import { translate } from 'vue-gettext';

const { gettext: $gettext } = translate;

export default {
  BANK_ACCOUNT__INVALID: $gettext('Invalid bank account'),
  BUSINESS_ID__INVALID: $gettext('Invalid business ID'),
  CONFIRM_CODE__INVALID: $gettext('Invalid confirmation code'),
  EMAIL__INVALID: $gettext('Invalid email'),
  ERROR__UNKNOWN: $gettext('Unexpected error'),
  ERROR__VALIDATION: $gettext('Validation error'),
  TERMS__SAVE_FAILED: $gettext('The loan terms could not be saved'),
  IBAN__INVALID: $gettext('Invalid account number'),
  IDENTIFICATION__CANCELLED: $gettext('Identification cancelled'),
  IDENTIFICATION__ERROR: $gettext('Identification failed'),
  LICENSE_NUMBER__INVALID: $gettext('Invalid license number'),
  PHONE_NUMBER__INVALID: $gettext('Invalid phone number'),
  PAGE_NOT_FOUND: $gettext('Page not found'),
  REQUIRE_ONE: $gettext('Choose at least one option'),
  REQUIRED_CHECKBOX: $gettext('Mandatory check box'),
  REQUIRED_FIELD: $gettext('Mandatory field'),
  SSN__INVALID: $gettext('Invalid Social Security Number'),
  VALUE__BETWEEN: $gettext('Enter a value between {min} and {max}'),
  VALUE__LENGTH: $gettext('Give {max} characters'),
  VALUE__NOT_UNIQUE: $gettext('The value must be unique'),
  VALUE__INTEGER: $gettext('The value entered must be an integer'),
  VALUE__INVALID: $gettext('Invalid value'),
  VERIFICATION_CODE__MAXIMUM_ATTEMPTS_REACHED: $gettext('You have entered the incorrect verification code too many times.'),
};
