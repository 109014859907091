const isNullOrUndefined = (...values) => values
  .every(value => value === null || value === undefined);

const validateDecimals = (value, { decimals = '*', separator = '.' }) => {
  if (isNullOrUndefined(value) || value === '') {
    return false;
  }

  if (Array.isArray(value)) {
    return value.every(val => validateDecimals(val, { decimals, separator }));
  }

  // if is 0.
  if (Number(decimals) === 0) {
    return /^-?\d*$/.test(value);
  }

  const regexPart = decimals === '*' ? '+' : `{1,${decimals}}`;
  const regex = new RegExp(`^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`);

  if (!regex.test(value)) {
    return false;
  }

  const parsedValue = parseFloat(value);

  // eslint-disable-next-line
  return parsedValue === parsedValue;
};

const maxValue = (value, { max }) => {
  if (isNullOrUndefined(value) || value === '') {
    return false;
  }

  if (Array.isArray(value)) {
    return value.length > 0 && value.every(val => maxValue(val, max));
  }

  return Number(value) <= Number(max);
};

export default {
  decimal: {
    validate: (value, decimals) => validateDecimals(value, decimals),
    params: ['decimals'],
  },

  max_value: {
    validate: (value, max) => maxValue(value, max),
    params: ['max'],
  },

  error: {
    validate: (_, { error }) => error === 'false',
    params: ['error'],
  },

  unique: {
    validate: (value, { values }) => !values.includes(value),
    params: ['values'],
  },

  requireOne: {
    validate: (_, { value }) => parseInt(value, 10) > 0,
    params: ['value'],
  },

  iban: {
    validate: (value) => {
      const v = value.replace(/\s/g, '');
      const regex = /^[fiFI]{2}\d{16}$/;
      return regex.test(v);
    },
  },

  bankAccount: {
    validate: (value) => {
      const v = value.replace(/\s/g, '');
      const regex = /^[0-9- ]{7,}$/;
      return regex.test(v);
    },
  },

  businessId: {
    validate: (value) => {
      const regex = /^[0-9]{7}-[A-Za-z0-9]{1}$/;
      return regex.test(value);
    },
  },

  phone: {
    validate: (value) => {
      const regex = /^(\+)?[0-9]{7,}$/;
      return regex.test(value);
    },
  },

  ssn: {
    validate: (value) => {
      const regex = /^(0?[1-9]|[12][0-9]|3[01])(0?[1-9]|1[012])\d{2}[-,+,A]\d{3}(?![GIOQgioq])[0-9, A-Y]$/;
      return regex.test(value);
    },
  },

  seSsn: {
    validate: (value) => {
      const regex = /^(19|20)?\d{6}[-+ ]?\d{4}$/;
      return regex.test(value);
    },
  },

  licenseNumber: {
    validate: (value) => {
      const regex = /^[A-Za-z0-9]{1,4}-[A-Za-z0-9]{1,4}$/;
      return regex.test(value);
    },
  },

  notNegativeNumber: {
    validate: (value) => {
      let number = value;
      const regex = /^\d+(.\d{0,2})?$/;

      if (typeof number === 'string') {
        number = parseFloat(number.replace(',', '.'));
      }

      return regex.test(number);
    },
  },

  // do not use this in templates, CwCheckbox will convert 'required' to this
  requiredCheckbox: {
    validate: val => val === 'on',
  },
};
