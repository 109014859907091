export const ALERT__SET = 'ALERT__SET';
export const DATA__SET = 'DATA__SET';
export const DATALAYER_APPLOADED__SET = 'DATALAYER_APPLOADED__SET';
export const DATALAYER_STEP_DATA__SET = 'DATALAYER_STEP_DATA__SET';
export const DIALOG__ADD = 'DIALOG__ADD';
export const DIALOG__REMOVE = 'DIALOG__REMOVE';
export const API_ERROR__REMOVE = 'API_ERROR__REMOVE';
export const API_ERROR__ADD = 'API_ERROR__ADD';
export const EXCEPTION__ADD = 'EXCEPTION__ADD';
export const EXCEPTION__CLEAR = 'EXCEPTION__CLEAR';
export const EXCEPTION__REMOVE = 'EXCEPTION__REMOVE';
export const HERO_STATE__SET = 'HERO_STATE__SET';
export const INITIAL_DATA__SET = 'INITIAL_DATA__SET';
export const IDENTIFICATION_RESULT__SET = 'IDENTIFICATION_RESULT__SET';
export const IS_CURRENT_CUSTOMER__SET = 'IS_CURRENT_CUSTOMER__SET';
export const MOBILE__SET = 'MOBILE__SET';
export const NOTIFICATION__ADD = 'NOTIFICATION__ADD';
export const NOTIFICATION__REMOVE = 'NOTIFICATION__REMOVE';
export const OFFERS__SET = 'OFFERS__SET';
export const TRANSFER_SESSION = 'TRANSFER_SESSION';
export const SIGNING_RIGHTS__SET = 'SIGNING_RIGHTS__SET';
export const PRODUCT__SET = 'PRODUCT__SET';
export const PURCHASE_SET = 'PURCHASE_SET';
export const SELECTED_OFFER__SET = 'SELECTED_OFFER__SET';
export const STATE__RESET = 'STATE__RESET';
export const SUMMARY_APPLICATION__SET = 'SUMMARY_APPLICATION__SET';
export const SUMMARY_WITHDRAWAL__SET = 'SUMMARY_WITHDRAWAL__SET';
export const SHOW_PAGE_LOADER = 'SHOW_PAGE_LOADER';
export const COMPANY_CONNECTIONS__SET = 'COMPANY_CONNECTIONS__SET';
export const TERMS__LIST = 'TERMS__LIST';
export const WORKFLOW_SET = 'WORKFLOW_SET';
export const UPDATE__TERM_VALUE = 'UPDATE__TERM_VALUE';
