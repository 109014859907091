import Vue from 'vue';
import store from '@shared/store';

/**
 * Handle Google analytics
 */
export const analytics = {
  /**
   * Process and push data to the dataLayer
   *
   * @param {Object} to - Route destination
   */
  createData(to) {
    // prevent dataLayer push on 'changePage'
    if (to.params.action === 'changePage') return;

    const initialData = store.getters['application/getInitialData'];
    const purchase = store.getters['application/getPurchase'];
    const keys = Object.keys(initialData);
    const product = store.getters['application/getProduct'];
    const selectedOffer = store.getters['application/getSelectedOffer'];
    const completePages = ['complete', 'carLoanComplete', 'instalmentLoanComplete'];

    const gaData = {};

    /**
     * Basic properties
     */
    gaData.event = 'gaDataLoaded';
    gaData.productKey = initialData.productKey;
    gaData.path = to.fullPath;

    /**
     * Start page properties
     */
    if (to.params.action === 'start') {
      if (keys.indexOf('appliedLoanLimit') >= 0 && keys.indexOf('appliedInstalmentAmount') >= 0) {
        gaData.appliedLoanLimit = initialData.appliedLoanLimit;
        gaData.appliedInstalmentAmount = initialData.appliedInstalmentAmount;
      }

      if (keys.indexOf('withdrawalAmount') >= 0) {
        gaData.withdrawalAmount = initialData.withdrawalAmount;
      }

      gaData.pointOfOrigin = initialData.pointOfOrigin;
    }

    /**
     * Complete page properties
     */
    if (completePages.indexOf(to.params.action) >= 0) {
      const price = (parseInt(initialData.withdrawalAmount, 10) / 100).toFixed(2);
      const loanLimit = (parseInt(selectedOffer.loanLimit, 10) / 100).toFixed(2);

      gaData.transactionId = initialData.loanId;
      gaData.userId = initialData.customerId;
      gaData.loanLimit = loanLimit;
      gaData.loanType = selectedOffer.type;

      const ecommerce = {
        currencyCode: 'EUR',
        add: {
          products: [{
            name: gaData.loanType,
            id: initialData.loanId,
            price,
            brand: product.productDisplayName,
            metric1: price,
            metric2: loanLimit,
            quantity: 1,
          }],
        },
      };

      gaData.ecommerce = ecommerce;

      const distanceDirection = {
        event: 'distanceDirection',
      };
      if (keys.indexOf('direction') >= 0) {
        distanceDirection.direction = initialData.direction;
      }
      if (keys.indexOf('distance') >= 0) {
        distanceDirection.distance = initialData.distance;
      }
      /**
       * Check if purchase event is set to be pushed to prevent duplicate events
       */
      if (purchase) {
        this.dataLayerPush(distanceDirection);
      }
    }

    /**
     * After application is created
     */
    if (keys.indexOf('id') >= 0) {
      gaData.applicationId = initialData.id;
      gaData.status = initialData.status;
    }

    this.dataLayerPush(gaData);
  },

  /**
   * Push data to dataLayer
   */
  dataLayerPush(data) {
    if (!window.dataLayer) return;

    window.dataLayer.push({
      ...data,
      event: `old_${data.event}`,
    });
  },
};

export function afterGuards(to, from) {
  analytics.createData(to);
  store.dispatch('togglePageLoader');
  store.dispatch('dataLayer/handlePageChange', { refresh: !from.name });
}

function beforeGuards(to, from, next) {
  if (to.name !== 'changePage') {
    const { hashId, action: currentPage } = to.params;

    Vue.prototype.$eventLogger.handlePageChange({ hashId, currentPage }); // eslint-disable-line
  }

  next();
}

/**
 * Create navigation guards for a router instance.
 *
 * @param {Object} router - Router instance
 */
export function createGuards(router) {
  /**
   * Handle route change rules
   */
  router.beforeEach(beforeGuards);
  router.afterEach(afterGuards);
}
