/* eslint-disable no-underscore-dangle */
/* eslint no-param-reassign: ["error", { "props": false }] */
/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import Vue from 'vue';
import clone from 'lodash.clonedeep';
import { sleep } from '@shared/utils/sleep';
import {
  API_ERROR__ADD,
  API_ERROR__REMOVE,
  COMPANY_CONNECTIONS__SET,
  DATA__SET,
  INITIAL_DATA__SET,
  IS_CURRENT_CUSTOMER__SET,
  OFFERS__SET,
  PRODUCT__SET,
  PURCHASE_SET,
  SELECTED_OFFER__SET,
  SIGNING_RIGHTS__SET,
  STATE__RESET,
  SUMMARY_APPLICATION__SET,
  SUMMARY_WITHDRAWAL__SET,
  TERMS__LIST,
  WORKFLOW_SET,
  UPDATE__TERM_VALUE,
} from '@shared/store/mutation-types';
import {
  acceptTerms,
  getAdTrackingHtml,
  getApplication,
  getCompanyConnections,
  getLoanOffers,
  getBrokeredOffers,
  getPopulationData,
  transferSession,
  getSigningRights,
  getProduct,
  getUniqueTrustPilotLink,
  isCurrentCustomer,
  listTerms,
  selectOffer,
  setData,
  submit,
  initializeLoanApplication,
} from '@shared/store/utils/api';
import router from '@shared/router';

// set initial state (used to reset the store)
const setInitialState = () => ({
  applicationSummary: [],
  data: {},
  apiErrors: [],
  ga: '',
  gac: '',
  initialData: {},
  isCurrentCustomer: false,
  offers: [],
  signingRights: {},
  product: {},
  selectedOffer: {},
  withdrawalSummary: [],
  purchase: false,
  companyConnections: [],
  terms: [],
  offerRedirectUrl: 'notset',
  workflow: '',
});

// state
const state = setInitialState();

// getters
const getters = {
  checkValidity: state => field => state.apiErrors.indexOf(field) === -1,
  getApplicationSummary: state => state.applicationSummary,
  getBrand: (state) => {
    let brand;

    if (state.product.productName) {
      brand = state.product.productName.split('.')[0].toLowerCase();
    }

    if (brand === 'saldo' && state.product.loanType === 'carInstalmentLoan') {
      brand = 'saldoCarLoan';
    }

    return brand;
  },
  getCurrentPage: state => state.initialData.currentPage,
  getData: state => state.data,
  apiErrors: state => state.apiErrors,
  getInitialData: state => state.initialData,
  getIsCurrentCustomer: state => state.isCurrentCustomer,
  isSaldo: (_, allGetters) => allGetters.getBrand === 'saldo',
  getLoanType: state => state.product.loanType,
  isCarLoan: (_, allGetters) => allGetters.getLoanType === 'carInstalmentLoan',
  getStateWorkflow: state => state.workflow, // this is from state
  getWorkflowType: state => state.initialData.workflowType, // this is from app data
  isBrokered: state => state.workflow === 'broker',
  isLender: state => state.workflow === 'lender',
  getPricingApiId: state => state.product.pricingApiId,
  getProduct: state => state.product,
  getProductUrl: state => state.product.url,
  getLoanOffers: state => state.offers,
  getBrokeredOffers: state => state.offers,
  getOfferRedirectUrl: state => state.initialData.offerRedirectUrl,
  getSelectedOffer: state => state.selectedOffer,
  getWithdrawalSummary: state => state.withdrawalSummary,
  getPurchase: state => state.purchase,
  getTerms: state => clone(state.terms).map(t => ({ ...t, value: t.acceptTime ? 'on' : 'off' })),
  expirationDate: (state, allGetters) => {
    if (!state.initialData.createTime) return '';
    const { $filters: { date } } = Vue.prototype;
    const createTime = new Date(state.initialData.createTime);
    const addDays = 7;
    return date(createTime.setDate(createTime.getDate() + addDays), {
      type: 'date',
      locale: allGetters.locale,
    });
  },
  requireIdentification: (state, allGetters) => {
    const {
      getInitialData: initialData,
      currentPath,
    } = allGetters;

    const {
      requireIdentification,
      isSessionIdentified,
    } = initialData;

    return {
      requirement: requireIdentification === '1' && !isSessionIdentified,
      url: currentPath,
    };
  },
  isSessionIdentified: (state, allGetters) => allGetters.getInitialData.isSessionIdentified,
  identified: (state, allGetters) => {
    const {
      requireIdentification,
      isSessionIdentified,
    } = allGetters;

    if (!requireIdentification.requirement) return true;

    return isSessionIdentified;
  },
  cameFromBroker: state => (
    typeof state.initialData.partnerName === 'string'
    && state.initialData.partnerName.length > 0
    && state.initialData.partnerName !== 'notset'
  ),

  isSaldoLimiitti: (state, allGetters) => {
    if (!allGetters.getSelectedOffer || !allGetters.getSelectedOffer.loanProgramName) return false;

    return allGetters.getSelectedOffer.loanProgramName
      .startsWith('Saldo.Finland') && allGetters.getSelectedOffer.type === 'continuousLoan';
  },
  isVippiJoustoluotto: (state, allGetters) => {
    if (!allGetters.getSelectedOffer || !allGetters.getSelectedOffer.loanProgramName) return false;

    return allGetters.getSelectedOffer.loanProgramName
      .startsWith('Vippi.fi') && allGetters.getSelectedOffer.type === 'continuousLoan';
  },

  hasEnoughSignees: (state) => {
    if (!state.initialData || !state.initialData.hasEnoughSignees) return false;
    return Boolean(state.initialData.hasEnoughSignees);
  },
  hashId: (state, allGetters) => {
    const {
      hashId,
      existingApplicationHash = null,
    } = allGetters.getInitialData;

    return existingApplicationHash || hashId;
  },
  currentPath: (state, allGetters) => {
    const {
      getInitialData: application,
      hashId = '-',
    } = allGetters;

    const {
      currentPage,
    } = application;

    return `/app/${currentPage}/${hashId}`;
  },

  /**
   * Returns the signees with names, roles and validation state included
   */
  signees: (state) => {
    if (!state.initialData || !state.initialData.signees) return [];

    const hasSignees = (state.signingRights
      && state.signingRights.signees
      && state.signingRights.signees.length > 0) || false;

    if (!hasSignees) return state.initialData.signees;

    const {
      signingRights: {
        signees,
      },
    } = state;

    const apiError = state.apiErrors.find(error => error.startsWith('signees.')) || 'null.-1.null';
    const [, index, field] = apiError.split('.');

    return clone(state.initialData.signees)
      .map((signee, i) => {
        const { name = '', roles = [] } = signees.find(s => s.ssn === signee.ssn);
        const valid = i !== parseInt(index, 10);

        const data = {
          ...signee,
          name,
          roles,
          valid,
        };

        if (!valid) {
          data.field = field;
        }

        return data;
      });
  },
  country: state => state.product.country
    || process.env.VUE_APP_COUNTRY,
  language: state => state.initialData.language
    || state.product.defaultLanguage
    || process.env.VUE_APP_LANGUAGE,
  locale: (state, allGetters) => `${allGetters.language}-${allGetters.country.toUpperCase()}`,
};

// actions
const actions = {
  async getApplication({ commit, dispatch }) {
    try {
      const { data } = await getApplication();
      commit(INITIAL_DATA__SET, data);
    } catch (e) {
      dispatch('addApiError', e.errors);
    }
  },

  async getSigningRights({ commit, dispatch }) {
    try {
      const { data } = await getSigningRights();
      commit(SIGNING_RIGHTS__SET, data);
    } catch (error) {
      dispatch('addApiError', error.errors);
    }
  },

  async initializeApplication({ commit, dispatch }, params = {}) {
    try {
      const { data } = await initializeLoanApplication(params);

      commit(INITIAL_DATA__SET, data);
    } catch (e) {
      await dispatch('addApiError', e.errors);
    }
  },

  async loadLoanApplication({ commit, dispatch, getters: allGetters }, params = {}) {
    try {
      const { data } = await getApplication(params);

      commit(INITIAL_DATA__SET, clone(data));

      /**
       * Fetch offers but only if customer has already selected
       * an offer AND the offers are not already fetched
       */
      if (data.selectedOfferId > 0 && allGetters.getLoanOffers.length <= 0) {
        await dispatch('loadLoanOffers');
      }
    } catch (e) {
      await dispatch('addApiError', e.errors);
    }
  },

  async loadBrokeredApplication({ commit, dispatch, getters: allGetters }, params = {}) {
    try {
      const { data } = await getApplication(params);

      commit(INITIAL_DATA__SET, clone(data));

      /**
       * Fetch offers but only if customer has already selected
       * an offer AND the offers are not already fetched
       */
      if (data.selectedOfferId > 0 && allGetters.getBrokeredOffers.length <= 0) {
        await dispatch('loadBrokeredOffers');
      }
    } catch (e) {
      await dispatch('addApiError', e.errors);
    }
  },

  /**
   * Sets the purchase Vuex property to desired state
   * @param {Boolean} payload
   */
  setPurchase({ commit }, payload) {
    commit(PURCHASE_SET, payload);
  },

  async acceptTerms({ dispatch }, payload) {
    try {
      await acceptTerms(payload);
    } catch (e) {
      await dispatch('addApiError', e.errors);
    }
  },

  async getAdTracking() {
    const response = await getAdTrackingHtml();

    if (response.success) return response.data.html;

    return null;
  },

  getUniqueTrustPilotLink() {
    return getUniqueTrustPilotLink();
  },

  transferSession() {
    return transferSession();
  },

  /**
  * Add API errors.
  *
  * @param {Array} payload - Error fields
  */
  addApiError({ commit }, payload) {
    commit(API_ERROR__ADD, payload);
  },

  /**
   * Get loan offers
   */
  async loadLoanOffers({ state, commit, getters: allGetters }) {
    if (!allGetters.identified) return [];

    const {
      data: receivedOffers,
    } = await getLoanOffers();

    const offers = receivedOffers
      .sort((a, b) => (
        parseInt(a.numberOfInstalments, 10) > parseInt(b.numberOfInstalments, 10) ? 1 : -1
      ))
      .map((offer) => {
        const deliveryFeePct = offer.deliveryFeePercent / 100;
        const deliveryFeeTotal = parseInt(offer.deliveryFee, 10) + deliveryFeePct * offer.loanLimit;

        return { ...offer, deliveryFeeTotal };
      });

    commit(OFFERS__SET, offers);

    if (state.initialData.selectedOfferId) {
      const selectedOffer = offers
        .find(offer => offer.id === state.initialData.selectedOfferId);

      commit(SELECTED_OFFER__SET, selectedOffer);
    }

    return offers;
  },

  /**
   * Get brokered offers
   */
  async loadBrokeredOffers({ state, commit, getters: allGetters }) {
    if (!allGetters.identified) return [];

    const {
      data: receivedOffers,
    } = await getBrokeredOffers();

    const offers = receivedOffers
      .sort((a, b) => (
        parseInt(a.numberOfInstalments, 10) > parseInt(b.numberOfInstalments, 10) ? 1 : -1
      ))
      .map((offer) => {
        const deliveryFeePct = offer.deliveryFeePercent / 100;
        const deliveryFeeTotal = parseInt(offer.deliveryFee, 10) + deliveryFeePct * offer.loanLimit;

        return { ...offer, deliveryFeeTotal };
      });

    commit(OFFERS__SET, offers);

    if (state.initialData.selectedOfferId) {
      const selectedOffer = offers
        .find(offer => offer.id === state.initialData.selectedOfferId);

      commit(SELECTED_OFFER__SET, selectedOffer);
    }

    return offers;
  },

  /**
   * Get population data
   */
  async getPopulationData(context, { hashId }) {
    const { data } = await getPopulationData({ hashId });
    return data;
  },

  /**
   * Get product details
   */
  async getProduct({ commit }, { hashId }) {
    const product = await getProduct({ hashId });

    this._vm.$language.current = product.data.defaultLanguage; // eslint-disable-line
    commit(PRODUCT__SET, product.data);
  },

  /**
   * Get company connections of user who has been identified by tupas
   */
  async getCompanyConnections({ commit }) {
    const response = await getCompanyConnections();
    commit(COMPANY_CONNECTIONS__SET, response.data);
  },

  /**
   * Check if applicant is an existing customer
   */
  async isCurrentCustomer({ commit }) {
    const response = await isCurrentCustomer();
    commit(IS_CURRENT_CUSTOMER__SET, response.data.result);
  },

  /**
   * Get loan terms
   */
  async listTerms({ commit, dispatch }) {
    try {
      const response = await listTerms();
      const terms = response.data.map(term => ({ ...term, value: term.acceptTime ? 'on' : 'off' }));
      commit(TERMS__LIST, terms);
    } catch (e) {
      await dispatch('addApiError', e.errors);
    }
  },

  async updateWorkflow({ commit }, flow) {
    commit(WORKFLOW_SET, flow);
  },

  /**
   * Dispatches setData, and if succeeds, executes routine (i.e. changes page).
   *
   * @param dispatch
   * @param rootGetters
   * @param formParams - User inputted form data
   * @param routineParams - Params for runnign submit routine
   *
   * @throws error - This is needed in page components
   */
  async submit(
    { dispatch },
    { formParams, routineParams } = { formParams: null, routineParams: null },
  ) {
    if (formParams) {
      const { success } = await dispatch('setData', formParams); // May throw error

      // prevent executeRoutine execution ie. in case of back end validation failure
      if (!success) {
        dispatch('wait/end', 'SUBMIT_FORM', { root: true });
        return;
      }
    }

    if (routineParams) {
      // Push to dataLayer only if setData succeeds so that we don't log
      // page change for invalid or false state
      if (window.dataLayer) {
        window.dataLayer.push({
          event: 'old_submitStep',
          action: JSON.stringify(routineParams),
        });
      }

      await dispatch('executeRoutine', routineParams); // May throw error
      dispatch('wait/end', 'SUBMIT_FORM', { root: true });
    }
  },

  /**
   * Executes routine (which is basically a page change)
   *
   * @param dispatch
   * @param rootGetters
   * @param payload
   *
   * @throws error - This is needed in page components
   */
  async executeRoutine({ dispatch, getters: allGetters }, payload) {
    try {
      await submit(payload);
      await dispatch('loadLoanApplication');

      const timeout = process.env.NODE_ENV === 'development'
        && process.env.VUE_APP_TEST_CASE === 'e2e' ? 0 : 500;
      const {
        currentPage,
      } = allGetters.getInitialData;

      // prevent route change on ui tests
      if (currentPage === 'mockedCurrentPage') return;

      Vue.prototype.$bus.$emit('HERO_SLIDE_OUT');

      // Wait for Hero animation to finish
      await sleep(timeout);
      router.replace(`/changePage/${allGetters.hashId}`).catch(() => {});
    } catch (e) {
      const errorFields = e.errors.map(f => f.name);

      await dispatch('addApiError', errorFields);

      throw e;
    }
  },

  async updateSigningRights({ dispatch }) {
    await submit({
      uri: 'executeRoutine',
      routine: 'Modify Signee Information',
    });

    await dispatch('loadLoanApplication');
  },

  /**
   * Set application summary.
   *
   * @param {Object} payload - Application summary data
   */
  setApplicationSummary({ commit }, payload) {
    commit(SUMMARY_APPLICATION__SET, payload);
  },

  /**
   * Set application data.
   *
   * @param commit
   * @param dispatch
   * @param payload - Application data
   * @returns {Promise<void>}
   */
  async setData({ commit, dispatch }, payload) {
    try {
      const response = await setData(payload);

      commit(DATA__SET, payload);

      return response;
    } catch (e) {
      await dispatch('addApiError', e.errors);

      throw e;
    }
  },

  /**
   * Set withdrawal summary.
   *
   * @param {Object} payload - withdrawal summary data
   */
  setWithdrawalSummary({ commit }, payload) {
    commit(SUMMARY_WITHDRAWAL__SET, payload);
  },

  /**
   * Remove an API error
   *
   * @param {String} payload - Error field
   */
  removeApiError({ commit }, payload) {
    commit(API_ERROR__REMOVE, payload);
  },

  /**
  * Reset state to initial state.
  */
  resetState({ commit }) {
    commit(STATE__RESET);
  },

  setSelectedOffer({ commit }, offer) {
    commit(SELECTED_OFFER__SET, offer);
  },

  async submitSelectedOffer({ commit, dispatch }, { formParams, routineParams }) {
    await selectOffer({ offerId: formParams.selectedOffer.id }); // May throw error

    commit(SELECTED_OFFER__SET, formParams.selectedOffer);

    await dispatch('executeRoutine', routineParams); // May throw error
    this.$wait.end('SUBMIT_FORM');
  },
};

// mutations
const mutations = {
  /**
   * Set application data to state
   *
   * @param {Object} data - Application data
   */
  [DATA__SET](state, data) {
    state.data = data;
  },

  [API_ERROR__ADD](state, fields) {
    if (!fields) return;
    state.apiErrors = [...state.apiErrors, ...fields];
  },

  /**
   * Removes API error
   *
   * @param {String} field - Error field
   */
  [API_ERROR__REMOVE](state, field) {
    state.apiErrors = state.apiErrors.filter(error => error !== field);
  },

  /**
   * Set true if current customer
   *
   * @param {String} payload
   */
  [IS_CURRENT_CUSTOMER__SET](state, payload) {
    state.isCurrentCustomer = payload;
  },

  /**
   * Set offers
   *
   * @param {Object} data - offers
   */
  [OFFERS__SET](state, data) {
    state.offers = data;
  },

  /**
   * Set product details
   *
   * @param {Object} data - product
   */
  [PRODUCT__SET](state, data) {
    state.product = data;
  },

  [COMPANY_CONNECTIONS__SET](state, data) {
    state.companyConnections = data;
  },

  /**
   * Set application initial data to state
   *
   * @param {Object} data - Application initial data
   */
  [INITIAL_DATA__SET](state, data) {
    state.initialData = data;
  },

  [STATE__RESET](state) {
    const initial = setInitialState();
    Object.keys(initial)
      .forEach((key) => {
        this._vm.$set(state, key, initial[key]);
      });
  },

  /**
   * Set application summary data to state
   *
   * @param {Object} data - Application summary data
   */
  [SUMMARY_APPLICATION__SET](state, data) {
    state.applicationSummary = data;
  },

  /**
   * Set withdrawal summary data to state
   *
   * @param {Object} data - withdrawal summary data
   */
  [SUMMARY_WITHDRAWAL__SET](state, data) {
    state.withdrawalSummary = data;
  },

  [SELECTED_OFFER__SET](state, data) {
    state.selectedOffer = data;
  },

  [SIGNING_RIGHTS__SET](state, data) {
    state.signingRights = data;
  },

  [PURCHASE_SET](state, payload) {
    state.purchase = payload;
  },

  [TERMS__LIST](state, payload) {
    state.terms = payload;
  },

  [WORKFLOW_SET](state, flow) {
    state.workflow = flow;
  },

  [UPDATE__TERM_VALUE](state, { i, val }) {
    Vue.set(state.terms[i], 'value', val);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
