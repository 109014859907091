import axios from 'axios';
import { exceptions } from '@shared/config';
import store from '@shared/store';
import router from '@shared/router';

/**
 * Check if application state is rejected (4)
 */
const checkIfRejected = async () => {
  await store.dispatch('application/loadLoanApplication');
  const application = store.getters['application/getInitialData'];
  const rejected = application.status === '4';
  // if application state is rejected
  if (rejected) {
    // clear exceptions to prevent unnecessary dialogs
    store.dispatch('clearExceptions');
    // reroute
    router.replace(`/app/${application.currentPage}/${application.hashId}`);
  }
  return rejected;
};

/**
 * List of error codes that should raise an exception modal in Application.vue
 */
const exceptionTypes = {
  22: {
    template: 'FAILURE__TECHNICAL',
    checkForRejection: false,
  },
  101: {
    template: 'FAILURE__TECHNICAL',
    checkForRejection: false,
  },
  102: {
    template: 'FAILURE__TECHNICAL',
    checkForRejection: false,
  },
  103: {
    template: 'FAILURE__TECHNICAL',
    checkForRejection: false,
  },
  104: {
    template: 'FAILURE__TECHNICAL',
    checkForRejection: true,
  },
  107: {
    template: 'FAILURE__TECHNICAL',
    checkForRejection: true,
  },
  108: {
    template: 'FAILURE__TECHNICAL',
    checkForRejection: false,
  },
  109: {
    template: 'FAILURE__TECHNICAL',
    checkForRejection: false,
  },
  111: {
    template: 'FAILURE__TECHNICAL',
    checkForRejection: false,
  },
  113: {
    template: 'FAILURE__TECHNICAL',
    checkForRejection: false,
  },
  116: {
    template: 'APPLICATION__UNFINISHED',
    checkForRejection: false,
  },
  117: {
    template: 'CONFIRMATION_CODE__REJECTED',
    checkForRejection: false,
  },
  121: {
    template: 'IDENTIFICATION__INTERRUPTED',
    checkForRejection: false,
  },
  123: {
    template: 'COMPANY__NOT_FOUND',
    checkForRejection: false,
  },
};

const instance = axios.create({
  baseURL: window.environment.api || process.env.VUE_APP_API,
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});

/**
 * Custom API exception
 *
 * @method ApiException
 *
 * @param {Number} code - Error code
 * @param {Array} errors - Array of field errors
 * @param {Array} data
 */
export function ApiException(code, errors) {
  const exp = exceptions[code];
  this.message = exp.message;
  this.type = exp.type;
  this.errors = errors;
}

/**
 * Calls for CwApi2HttpController to execute given routine
 *
 * @param {String} url - API url to be called
 * @param {Object} data - Post parameters
 * @param {Object} opts
 * @returns {Promise} - Response from API
 */
async function fetch(url, data = {}, opts = {}) {
  if (process.env.VUE_APP_TEST_CASE === 'unit') {
    console.warn(`🐖 💨  Mocked default response: ${url}`);
    return {
      data: {
        success: true,
        data: [],
      },
    };
  }

  const hashId = opts.hashId || store.getters['application/hashId'];

  // prevent api request without hash
  if (!hashId && !['initializeLoanApplication', 'identify'].includes(url)) {
    if (process.env.NODE_ENV === 'development') {
      console.warn('hashId is missing...! (api.js)');
    }

    return null;
  }

  const response = await instance.post(url, { hashId, data });

  if (!response.data.success && !Object.keys(response.data).includes('errorFields')) {
    response.data.errorFields = []; //eslint-disable-line
  }
  // check if the error code is in the 'watch list'
  const watchedException = Object
    .prototype
    .hasOwnProperty
    .call(exceptionTypes, response.data.errorCode);

  // Handle field validation errors correctly
  if (!response.data.success && response.data.errorCode === 105) {
    store.dispatch('application/addApiError', response.data.errorFields);
    return response.data;
  }

  if (response.data.success || !watchedException) {
    return response.data;
  }

  let rejected = false;
  // if error code needs to be checked for rejection
  if (exceptionTypes[response.data.errorCode].checkForRejection) {
    rejected = await checkIfRejected();
  }

  // if application exists
  if (response.data.errorCode === 116) {
    await store.dispatch('application/loadLoanApplication', {}, { root: true });
  }

  // if application is not rejected
  if (!rejected) {
    // add the exception to store
    store.dispatch('addException', exceptionTypes[response.data.errorCode].template);
  }

  throw new ApiException(response.data.errorCode, response.data.errorFields);
}

// API METHODS -------------------

/**
 * Accepts single terms
 *
 * @method acceptTerms
 *
 * @param {Object} params
 * @param {String} params.termsId - The identifier of the terms to be acquired
 *
 * @returns {Object} - Api response object containing the terms data
 */
export function acceptTerms(params) {
  return fetch('acceptTerms', params);
}

export function getAdTrackingHtml() {
  return fetch('getAdTrackingHtml');
}

/**
 * Pass identification data to backend for processing
 *
 * @param {Object} params
 *
 * @returns {Object} - Api response object containing the redirect URL
 */
export function identify(params) {
  return fetch('identify', params);
}

/**
 * Get application retrieves application data
 *
 * @method getApplication
 *
 * @returns {Object} - Key value pairs of the application data response from the api
 */
export function getApplication(params = {}) {
  return fetch('getApplication', {}, params);
}

/**
 *  Get products and prices for brand
 * @param { String } brand
 */
export function getBrandPrices(brand) {
  return instance.post('getBrandPrices', { brand });
}

/**
 * Get tupas buttons.
 *
 * @param {Object} params
 * @param {String} params.id - Return route url.
 *
 * @returns {Object} - Object containing tupas payment form data.
 */
export function getIdentificationData(params) {
  return fetch('getIdentificationData', params);
}

export function getCompanyConnections() {
  return fetch('getCompanyConnections');
}

export function getLoanOffers() {
  return fetch('getLoanOffers');
}

export function getBrokeredOffers() {
  return fetch('getBrokeredOffers');
}

export function getPopulationData(params = {}) {
  return fetch('getPopulationData', {}, params);
}

/**
 * Get the procuration abstract and the awailable signees
 *
 * @param {Object} data
 *
 * @returns {Object}  - Api response object containing
 *                      the procuration abstract and the awailable signees
 */
export function getSigningRights() {
  return fetch('getSigningRights');
}

export function transferSession() {
  return fetch('transferSession');
}

/**
 * Get product details
 *
 * @method getProduct
 *
 * @returns {Object} - Api response object containing the product details
 */
export function getProduct(params = {}) {
  return fetch('getProduct', {}, params);
}

export function getUniqueTrustPilotLink() {
  return fetch('getUniqueTrustPilotLink');
}

export function initializeLoanApplication(params) {
  return fetch('initializeLoanApplication', params);
}

export function isCurrentCustomer() {
  return fetch('isCurrentCustomer');
}

export function getWorkflowForProduct(params) {
  return fetch('getWorkflowForProduct', params);
}

/**
 * Lists terms
 *
 * @method listTerms
 *
 * @returns {Object} - Api response object containing the terms list
 */
export function listTerms() {
  return fetch('listTerms');
}

/**
 * Sets data to current application
 *
 * @method setData
 *
 * @param {Object} params
 * @param {Object} params.data - Contains data consisting of key value pairs
 *
 * @returns {Object} - Key value pairs of the application data response from the api.
 */
export function setData(params) {
  return fetch('setData', params);
}

/**
 * Selects single offer
 *
 * @param {Object} params
 * @param {Number} params.offerId - Selected offer id
 *
 * @returns {Promise}
 */
export function selectOffer(params) {
  return fetch('selectOffer', params);
}

/**
 * Submit form
 *
 * @param {Object} params
 * @returns {Promise}
 */
export function submit(params = {}) {
  if (typeof params === 'object') {
    const uri = params.uri || 'executeRoutine';
    const routine = params.routine || '';
    return fetch(uri, { routine });
  }
  return fetch(params);
}

export function submitCarLoanStart() {
  return fetch('submitCarLoanStart');
}
