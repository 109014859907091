import PageDataLayerService from '@shared/services/dataLayer/PageDataLayerService';

class EecDataLayerService extends PageDataLayerService {
  /**
   * Datalayer push for add event
   */
  add() {
    this.push({
      event: 'eec.add',
      ecommerce: {
        currencyCode: this.currencyCode,
        add: {
          products: [this.product],
        },
      },
    });
  }

  /**
   * Datalayer push for checkout event
   *
   * @param {String} step - step number
   */
  checkout(params = {}) {
    this.push({
      event: 'eec.checkout',
      ecommerce: {
        currencyCode: this.currencyCode,
        checkout: {
          actionField: params,
          products: [this.product],
        },
      },
    });
  }

  /**
   * Datalayer push for checkoutOption event
   *
   * @param {String} option
   */
  checkoutOption({ option }) {
    this.push({
      event: 'eec.checkoutOption',
      ecommerce: {
        currencyCode: this.currencyCode,
        checkoutOption: {
          actionField: {
            step: this.step,
            option,
          },
        },
      },
    });
  }

  /**
   * Datalayer push for impressionClick event
   */
  impressionClick() {
    this.push({
      event: 'eec.impressionClick',
      ecommerce: {
        currencyCode: this.currencyCode,
        click: {
          actionField: {
            list: 'Loan offers',
          },
          products: [this.product],
        },
      },
    });
  }

  /**
   * Datalayer push for impressionView event
   *
   * @param {Array} impressions
   */
  impressionView(impressions = []) {
    this.push({
      event: 'eec.impressionView',
      ecommerce: {
        currencyCode: this.currencyCode,
        impressions,
      },
    });
  }

  /**
   * Datalayer push for add event
   */
  purchase(params = {}) {
    this.push({
      event: 'eec.purchase',
      ecommerce: {
        currencyCode: this.currencyCode,
        purchase: {
          actionField: params,
          products: [this.product],
        },
      },
    });
  }
}

export default EecDataLayerService;
