/* eslint-disable class-methods-use-this */
/* eslint-disable no-underscore-dangle */

// import ErrorService from '@/services/error';

// const ErrorService = {};

class BaseDataLayerService {
  constructor() {
    this._country = undefined;
    this._currencyCode = undefined;
    this._product = undefined;
    this._previousStep = undefined;
    this._step = undefined;
    this._steps = undefined;
    this._userId = undefined;

    // UTM parameters
    this.campaign = undefined;
    this.medium = undefined;
    this.source = undefined;

    // Make sure window.dataLayer exists
    window.dataLayer = window.dataLayer || [];
  }

  // ========== GETTERS ==========

  /**
   * Returns the country
   *
   * @returns {String}
   */
  get country() {
    try {
      if (!this._country) throw Error('Country is not defined');
    } catch (err) {
      this.handleErrors(err);
    }
    return this._country;
  }

  /**
   * Returns the current page
   *
   * @returns {String}
   */
  get currentPage() {
    try {
      if (!this._currentPage) throw Error('Current page is not defined');
    } catch (err) {
      this.handleErrors(err);
    }
    return this._currentPage;
  }

  /**
   * Returns the currency code
   *
   * @returns {String}
   */
  get currencyCode() {
    try {
      if (!this._currencyCode) throw Error('Currency code is not defined');
    } catch (err) {
      this.handleErrors(err);
    }
    return this._currencyCode;
  }

  /**
   * @returns {Boolean}
   */
  get disabled() {
    return !!sessionStorage.getItem('dataLayerDisabled');
  }

  /**
   * Returns the previous step number
   *
   * @returns {Number}
   */
  get previousStep() {
    try {
      if (!this._previousStep) throw Error('Previous step number is not defined');
    } catch (err) {
      this.handleErrors(err);
    }
    return this._previousStep;
  }

  /**
   * Returns the product
   *
   * @returns {String}
   */
  get product() {
    try {
      if (!this._product) throw Error('Product is not defined');
    } catch (err) {
      this.handleErrors(err);
    }
    return this._product;
  }

  /**
   * Returns the step number
   *
   * @returns {Number}
   */
  get step() {
    return this._step;
  }

  /**
   * Returns the steps
   *
   * @returns {Array}
   */
  get steps() {
    try {
      if (!this._steps) throw Error('Steps are not defined');
    } catch (err) {
      this.handleErrors(err);
    }
    return this._steps;
  }

  /**
   * Returns the userId
   *
   * @returns {String}
   */
  get userId() {
    try {
      if (!this._userId) throw Error('User ID is not defined');
    } catch (err) {
      this.handleErrors(err);
    }
    return this._userId;
  }

  // ========== SETTERS ==========

  /**
   * @param {Boolean} disable
   */
  set disabled(disable) {
    if (disable) {
      sessionStorage.setItem('dataLayerDisabled', true);
    } else {
      sessionStorage.removeItem('dataLayerDisabled');
    }
  }

  /**
   * Sets the country
   *
   * @param {String} country - country code
   */
  set country(country) {
    this._country = country;
  }

  /**
   * Sets the current page
   *
   * @param {String} currentPage - current page
   */
  set currentPage(currentPage) {
    this._currentPage = currentPage;
  }

  /**
   * Sets the currency code
   *
   * @param {String} currencyCode - 3 character currency code
   */
  set currencyCode(code) {
    this._currencyCode = code;
  }

  /**
   * Sets the previous step number
   *
   * @param {Number} previousStep - previous step number code
   */
  set previousStep(previousStep) {
    this._previousStep = previousStep;
  }

  /**
   * Sets the product
   *
   * @param {Object} product
   * @param {String} product.appliedProduct - applied product name
   * @param {String} product.brand - brand
   * @param {String} product.gaType - category of the loan (ie. instalmentLoan)
   * @param {String} product.instalmentAmount - initial instalment or withdrawal amount
   * @param {String} product.loanLimit - loan amount
   * @param {String} product.offerProductName - offered product name
   * @param {String} product.productName - loan product name
   */
  set product({
    brand = this.required('brand'),
    instalmentAmount: variant = this.required('instalmentAmount'),
    loanLimit: price = this.required('loanLimit'),
    offerProductName,
    appliedProduct = this.required('productName'),
    productName: name = this.required('productName'),
    type: category = this.required('type'),
  } = {}) {
    this._product = {
      name,
      price,
      quantity: 1,
      brand,
      category,
      variant,
      dimension18: appliedProduct,
      dimension19: offerProductName,
    };
  }

  /**
   * Sets the step number
   *
   * @param {Number} step - step number
   */
  set step(step) {
    this._previousStep = this._step;
    this._step = step;
  }

  /**
   * Sets the steps
   *
   * @param {Array} steps - steps
   */
  set steps(steps) {
    this._steps = steps;
  }

  /**
   * Sets the userId
   *
   * @param {String} userId - user ID string
   */
  set userId(id) {
    this._userId = id;
  }

  // ========== METHODS ==========

  disable() {
    this.disabled = true;
  }

  enable() {
    this.disabled = false;
  }

  getStepRange() {
    try {
      if (!this.step) return [];

      // Do not add skipped steps to the range on steps without fixed position
      if (this.step >= 100) {
        return [{
          [this.step]: this.steps[this.step - 1],
        }];
      }

      const range = (start, end, length = end - start + 1) => [...Array(length).keys()]
        .map(d => ({ [d + start]: this.steps[d + start - 1] }));

      return this._previousStep
        ? range(this.previousStep + 1, this.step)
        : [{ [this.step]: this.steps[this.step - 1] }];
    } catch (err) {
      this.handleErrors(err);
      return [];
    }
  }

  handleErrors(err) { // eslint-disable-line
    // ErrorService.setContext('Error data', {
    // message: 'Error in dataLayer',
    //   page: this._currentPage,
    // });
    // ErrorService.captureException(err);
  }

  /**
   * Datalayer push for real page load
   *
   * @param {Object} payload - payload for the dataLayer (ie. event)
   */
  push(payload) {
    try {
      if (!payload.event) throw Error('Event is not provided');
      if (this.disabled) return;

      window.dataLayer.push(payload);
    } catch (err) {
      this.handleErrors(err);
    }
  }

  /**
   * Throw an error if required parameter is not provided
   *
   * @param {String} parameter - parameter name
   *
   * @throws
   */
  required(parameter) {
    if (process.env.NODE_ENV === 'production') return;

    console.error(`Required parameter [${parameter}] is not provided`);
  }
}

export default BaseDataLayerService;
