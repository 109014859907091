import store from '@shared/store';
import { notification } from '@shared/config';
import { DataLayer } from '@shared/services/dataLayer';

export default {
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/identification/error',
      name: 'identificationError',
      component: () => import('./actions'),
      props: () => ({
        action: 'error',
        clientError: {
          code: '500',
          message: notification.IDENTIFICATION__ERROR,
        },
      }),
      meta: {
        auth: false,
        hero: false,
        required: {},
      },
    },
    {
      path: '/',
      component: () => import('@shared/components/Dashboard'),
      meta: {
        auth: true,
        hero: false,
        required: {},
      },
      children: [
        {
          path: '/start/:tokenId',
          component: {
            template: '<div></div>',
          },
          name: 'start',
          meta: {
            auth: true,
            hero: false,
            required: {},
          },
          beforeEnter: (to, from, next) => {
            const {
              tokenId = null,
            } = to.params;

            const tokenIdPresent = tokenId && tokenId !== 'undefined';

            if (!tokenIdPresent) return next({ name: 'applicationNotFound' });

            return next({
              name: 'application',
              query: {
                tokenId,
              },
            });
          },
        },
        {
          path: '/app',
          component: () => import('@shared/pages/Application'),
          name: 'application',
          meta: {
            auth: true,
            hero: false,
            required: {},
          },
          beforeEnter: async (to, from, next) => {
            const { query } = to;

            const initializeValues = ['productKey', 'tokenId'];
            if (!initializeValues.some(key => Object.keys(query).includes(key))) {
              return next();
            }

            await store.dispatch('application/initializeApplication', query, { root: true });

            const application = store.getters['application/getInitialData'];

            // Redirect to product selection should it exist
            if (application.productSelection && query.tokenId) {
              return next(`/productSelection/${query.tokenId}`);
            }

            const params = {
              path: store.getters['application/currentPath'],
              replace: true,
            };

            return next(params);
          },
          children: [
            {
              path: ':action/:hashId',
              component: () => import('./actions'),
              name: 'actions',
              meta: {
                auth: true,
                hero: false,
                required: {},
              },
              props: route => ({ action: route.params.action }),
              beforeEnter: async (to, from, next) => {
                const {
                  action,
                  hashId,
                } = to.params;

                const fetchProduct = store.dispatch('application/getProduct', { hashId }, { root: true });
                const fetchApplication = store.dispatch('application/loadLoanApplication', { hashId }, { root: true });

                await Promise.all([
                  fetchProduct,
                  fetchApplication,
                ]);

                if (action === 'continue') {
                  store.dispatch('dataLayer/restoreOriginalUtmParameters');
                }

                const application = store.getters['application/getInitialData'];

                // Prevents from loops
                if (application.currentPage === to.params.action) {
                  return next();
                }

                const params = {
                  path: store.getters['application/currentPath'],
                  replace: true,
                };

                return next(params);
              },
            },
            {
              path: 'applicationNotFound',
              component: () => import('./actions'),
              props: () => ({ action: 'applicationNotFound' }),
              name: 'applicationNotFound',
              meta: {
                auth: true,
                hero: false,
                required: {},
              },
            },
          ],
        },
        {
          path: '/changePage/:hashId',
          name: 'changePage',
          meta: {
            auth: true,
            hero: false,
            required: {},
          },
          beforeEnter: async (to, from, next) => {
            const application = store.getters['application/getInitialData'];

            /**
             * Fetch application in case if does not exists OR there is
             * already an existing application with existingApplicationHash.
             *
             * @type {boolean}
             */
            const fetchApplication = Object.keys(application).length <= 0 || application.currentPage === 'applicationExists';

            if (fetchApplication) {
              const hashId = application.existingApplicationHash || to.params.hashId;

              await store.dispatch('application/loadLoanApplication', { hashId }, { root: true });
            }

            const params = {
              path: store.getters['application/currentPath'],
              replace: true,
            };

            return next(params);
          },
        },
        {
          path: '/productSelection/:hashId',
          component: () => import('./actions'),
          props: () => ({ action: 'productSelection' }),
          name: 'productSelection',
          meta: {
            auth: false,
            hero: false,
            required: {},
          },
          beforeEnter: async (to, from, next) => {
            const {
              hashId,
            } = to.params;

            await store.dispatch('application/getProduct', { hashId }, { root: true });

            return next();
          },
        },
        {
          path: '/linkExpired',
          component: () => import('./actions'),
          props: () => ({ action: 'linkExpired' }),
          name: 'linkExpired',
          meta: {
            auth: true,
            hero: false,
            required: {},
          },
        },
        {
          path: '/identification',
          name: 'identification',
          component: () => import('@shared/components/FormLoader'),
          beforeEnter: async (to, from, next) => {
            if (Object.keys(to.query).length === 0) return next();

            try {
              await store.dispatch('identification/identify', to.query);

              DataLayer.setAuthenticationResult({ result: 'pass' });
              DataLayer.setApplicationStatus({ status: 'identified' });

              return next({ path: store.state.identification.targetPath, replace: true });
            } catch (error) {
              DataLayer.setAuthenticationResult({ result: 'fail' });

              return next({ name: 'identificationError', replace: true });
            }
          },
        },
        {
          path: '*',
          redirect: to => ({ name: 'pageNotFound', query: to.query }),
        },
      ],
    },
    {
      path: '*',
      name: 'pageNotFound',
      component: () => import('./actions'),
      props: () => ({
        action: 'error',
        clientError: {
          code: '404',
          message: notification.PAGE_NOT_FOUND,
        },
      }),
      meta: {
        auth: false,
        hero: false,
        required: {},
      },
    },
  ],
};
