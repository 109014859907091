<template>
  <v-app
    id="app"
    v-resize="handleResize"
    :class="rootClasses"
  >
    <transition name="fade">
      <v-progress-linear
        v-if="$wait.waiting('worker')"
        id="cw-worker-loader"
        color="primary"
        height="2"
        indeterminate
      />
    </transition>

    <transition name="zoom">
      <div
        v-if="showPageLoader"
        id="progress-container"
        class="progress-container"
      >
        <div class="progress-container-inner">
          <component :is="logo"/>
          <v-progress-linear
            :indeterminate="true"
            color="purple-one darken-2"
            height="1"
          />
        </div>
      </div>
    </transition>

    <router-view appear/>
    <v-snackbar
      id="notifications"
      v-model="show"
      :color="notification.type !== 'normal' ? notification.type : ''"
      :timeout="6000"
      bottom
      right
    >
      {{ notification.message }}
    </v-snackbar>
    <cw-confirmation-dialog/>
  </v-app>
</template>

<script>
/* eslint-disable no-underscore-dangle */
import { mapGetters, mapActions } from 'vuex';
import { initializeChat } from '@saldo/giosg/dist';

export default {
  name: 'App',

  components: {
    'cw-confirmation-dialog': () => import('@shared/components/ConfirmationDialog'),
  },

  data() {
    return {
      notification: {
        type: null,
        message: '',
      },
      show: false,
    };
  },

  computed: {
    ...mapGetters({
      notifications: 'notifications/getNotifications',
      showPageLoader: 'showPageLoader',
      product: 'application/getProduct',
    }),

    logo() {
      if (!this.product || !this.product.brand) return '';
      return () => import('@shared/assets/images/logos/' + this.product.brand.toLowerCase() + '.svg'); // eslint-disable-line
    },

    rootClasses() {
      return {
        'application--mobile': this.$vuetify.breakpoint.xsOnly,
        'disable-animations': ['e2e', 'ui'].includes(process.env.VUE_APP_TEST_CASE),
      };
    },
  },

  watch: {
    /**
     * Run displayNotification on notification queue change.
     */
    notifications: {
      handler(items) {
        if (!items.length) return;

        this.displayNotification();
      },
      immediate: true,
      deep: true,
    },

    /**
     * Set page title on product load
     */
    product: {
      handler(product) {
        if (!product || !product.brand) return;

        const { brand } = product;
        document.title = this.$gettextInterpolate(this.$gettext('Loan application - %{ brand }'), { brand });
      },
      immediate: true,
      deep: true,
    },
  },

  mounted() {
    this.handleResize();
    initializeChat({ id: 4366 });
  },

  methods: {
    ...mapActions({
      removeNotification: 'notifications/removeNotification',
      toggleMobile: 'toggleMobile',
    }),

    handleResize() {
      // Replace hard coded value with Vuetify's smAndDown when it works properly
      this.toggleMobile(window.innerWidth < 960);
    },

    /**
     * Displays the first notification in the notification queue.
     */
    displayNotification() {
      if (!this.show) {
        [this.notification] = this.notifications;
        this.show = true;
        setTimeout(() => {
          this.show = false;
          this.removeNotification(this.notification);
        }, 7000);
      }
    },
  },
};
</script>

<style lang="scss">
  html {
    margin-top: 0 !important;

    // Styles for the 'Browser update' plugin
    .buorg {
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
      background-color: #ed6607;
      padding: 9px;
      line-height: 1.7em;
      font-family: 'Roboto', sans-serif;
      margin: 0;
      font-size: 14px;
      text-align: center;
      z-index: auto;

      &,
      a {
        color: white;
      }
    }

    &.overflow-y-hidden {
      body {
        overflow-y: scroll;
      }
    }
  }

  .relative {
    position: relative !important;
  }

  .router-link {
    text-decoration: none;
  }

  #cw-worker-loader {
    left: 0;
    margin: 0;
    position: absolute;
    top: 0;
    z-index: 1000;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  .v-menu__content {
    &.v-menuable__content__active {
      z-index: 204 !important;
    }
  }

  .slide-fade-leave-active {
    transition: all 0.4s linear;

    .progress-container-inner {
      transition: all 0.5s cubic-bezier(0.75, 0, 0.25, 1);
    }
  }

  .slide-fade-leave-to {
    opacity: 0;

    .progress-container-inner {
      transform: translateX(75px);
    }
  }

  .zoom-leave-active {
    transition: all 0.4s linear;

    .progress-container-inner {
      transition: all 0.5s cubic-bezier(0.75, 0, 0.25, 1);
    }
  }

  .zoom-leave-to {
    opacity: 0;

    .progress-container-inner {
      transform: scale(1.5);
    }
  }

  .progress-container {
    align-items: center;
    background-image: radial-gradient(ellipse farthest-corner at center top, #fff 0%, #ddd 100%);
    display: flex;
    height: 100vh;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 10;

    .progress-container-inner {
      max-width: 100%;
      padding: 0 25px;

      svg {
        max-width: 100%;
        width: 100%;
      }

      .v-progress-linear {
        margin: 2rem 0 0;
        color: rgb(11, 89, 193);
        background-color: white;
        width: 100%;
      }
    }
  }

  @media (min-width: 600px) {
    .progress-container {
      .progress-container-inner {
        max-width: 500px;
        padding: 0 50px;

        .v-progress-linear {
          margin: 3rem 0 0;
        }
      }
    }
  }

  .application--wrap {
    display: block !important;
  }

  .input-group__error + .input-group__counter--error {
    display: none;
  }

  .cw-toolbar__main {
    width: 100vw;
  }

  .application--mobile {
    .cw-toolbar__main {
      .v-toolbar__content {
        background-color: #fff;
      }
    }

    .v-stepper__header {
      height: 48px;
      margin: -8px -8px 0;

      .v-stepper__step {
        padding: 0 24px;
      }

      .v-stepper__step__step {
        width: 24px;
        height: 24px;
      }
    }

    .cw-application__content {
      box-shadow: none;
      margin: 0;
    }

    .cw-application__title {
      > div {
        i {
          margin-right: 8px !important;
        }
      }
    }

    .content__wrapper {
      margin: 0 !important;
      padding: 8px !important;

      > h1 {
        .icon {
          margin-right: 8px !important;
        }
      }
    }
  }

  .disable-animations *,
  .disable-animations *:after,
  .disable-animations *:before {
    transition: none !important;
    transition-duration: 0ms !important;
    transition-delay: 0ms !important;
    animation-delay: 0ms !important;
    animation-duration: 0ms !important;
  }
</style>
