/* eslint no-param-reassign: ["error", { "props": false }] */
/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import * as types from './mutation-types';
// import initialState from './state';

const mutations = {
  /**
   * Add an exception to the state
   *
   * @param {Object} payload - Exception
   */
  [types.EXCEPTION__ADD](state, payload) {
    state.exceptions.push(payload);
  },

  /**
   * Clear all exceptions from the state
   */
  [types.EXCEPTION__CLEAR](state) {
    state.exceptions = [];
  },

  /**
   * Remove an exception from the state
   *
   * @param {Object} payload - Exception
   */
  [types.EXCEPTION__REMOVE](state, payload) {
    state.exceptions.splice(state.exceptions.indexOf(payload), 1);
  },

  [types.MOBILE__SET](state, payload) {
    state.mobile = payload;
  },

  [types.SHOW_PAGE_LOADER](state, payload) {
    state.showPageLoader = payload;
  },
};

export default mutations;
