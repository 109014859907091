/* eslint no-shadow: ["error", { "allow": ["state"] }] */

import {
  PRODUCT__SET,
} from '@shared/store/mutation-types';
import { translate } from 'vue-gettext';

const {
  ngettext: $ngettext,
  gettextInterpolate: $gettextInterpolate,
} = translate;

// set initial state (used to reset the store)
const setInitialState = () => ({
  product: {},
});

// state
const state = setInitialState();

// getters
const getters = {
};

// actions
const actions = {
  /**
   * Get a product from product json
   * @param {String} product - product name
   */
  async getProduct({ commit }, product) {
    try {
      const data = await import('@shared/products/data/' + product + '.json').then(m => m.default || m); // eslint-disable-line
      commit(PRODUCT__SET, data);
    } catch (e) {
      console.error('Pricing product fetch failed', e);
    }
  },
};

// mutations
const mutations = {
  /**
   * Set a product to state
   */
  [PRODUCT__SET](state, data) {
    const prices = data.prices.reduce((previous, current, index) => {
      previous.set(index, {
        amount: current.amount,
        periods: current.instalments.map(({ count }, i) => {
          if (!data.periodMin || data.periodMin > count) {
            data.periodMin = count; // eslint-disable-line
          }

          if (!data.periodMax || data.periodMax < count) {
            data.periodMax = count; // eslint-disable-line
          }

          return {
            index: i,
            text: $gettextInterpolate($ngettext('%{value} mo', '%{value} mos'), {
              value: count,
            }),
            value: count,
          };
        }),
        instalmentAmounts: current.instalments.map(instalment => instalment.amount),
      });

      return previous;
    }, new Map());

    state.product = {
      amountMax: data.amountMax,
      amountMin: data.amountMin,
      currency: data.currency,
      disclaimer: data.disclaimer,
      periodMax: data.periodMax,
      periodMin: data.periodMin,
      prices,
    };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
